<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I apply for membership to Recreational Aviation Australia (RA-Aus)?</h2>
      <p>
        If you are using Light Sport Aircraft to conduct your flight training, you will
        eventually need to apply to the RA-Aus for membership. Light Sport Aircraft for
        the purpose of flight training can be identified by their registration prefix of
        <code>23</code> or <code>24</code>. Initially, you will be training under an
        RA-Aus Temporary Membership, which expires after 3 hours of flying or 3 months;
        whichever comes first. A full RA-Aus membership can be applied for online at the
        <a href="https://www.raa.asn.au/become-a-member/free-30-day-membership/">
        RA-Aus website
        </a>.
      </p>
      <p>
        You can find more information about the RA-Aus
        <router-link to="/ra-aus-documentation">here</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
